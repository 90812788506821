import EICU_I from '../assets/images/eicu/eicu-i.jpg';
import EICU_II from '../assets/images/eicu/eicu-ii.jpg';
import EICU_III from '../assets/images/eicu/eicu-iii.jpg';
import EICU_IV from '../assets/images/eicu/eicu-iv.jpg';
import EICU_V from '../assets/images/eicu/eicu-v.jpg';
import EICU_VI from '../assets/images/eicu/eicu-vi.jpg';
import EICU_VII from '../assets/images/eicu/eicu-vii.jpg';
import EICU_VIII from '../assets/images/eicu/eicu-viii.jpg';
import EICU_IX from '../assets/images/eicu/eicu-ix.jpg';
import EICU_X from '../assets/images/eicu/eicu-x.jpg';
import EICU_XI from '../assets/images/eicu/eicu-xi.jpg';
import EICU_XII from '../assets/images/eicu/eicu-xii.jpg';
import EICU_XIII from '../assets/images/eicu/eicu-xiii.jpg';
import EICU_XIV from '../assets/images/eicu/eicu-xiv.jpg';

import BrazilFlag from '../assets/flags/brazil-flag.png';
import BulgariaFlag from '../assets/flags/bulgaria-flag.png';
import CzechRepublicFlag from '../assets/flags/czech_republic-flag.png';
import DenmarkFlag from '../assets/flags/denmark-flag.png';
import EnglandFlag from '../assets/flags/england-flag.png';
import GermanyFlag from '../assets/flags/germany-flag.png';
import HungaryFlag from '../assets/flags/hungary-flag.png';
import IrelandFlag from '../assets/flags/ireland-flag.png';
import ItalyFlag from '../assets/flags/italy-flag.png';
import LithuaniaFlag from '../assets/flags/lithuania-flag.png';
import PolandFlag from '../assets/flags/poland-flag.png';
import PortugalFlag from '../assets/flags/portugal-flag.png';
import SlovakiaFlag from '../assets/flags/slovakia-flag.png';
import SpainFlag from '../assets/flags/spain-flag.png';
import UkraineFlag from '../assets/flags/ukraine-flag.png';

export default [
  {
    title: 'XIV',
    date: 'eicu.past_editions.dates.XIV',
    image: EICU_XIV,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cicbas',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.caum',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.curiv',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.caam',
        country: 'eicu.countries.bulgaria',
        country_flag: BulgariaFlag
      }
    ]
  },
  {
    title: 'XIII',
    date: 'eicu.past_editions.dates.XIII',
    image: EICU_XIII,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.ccoup',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.ecoromia',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.gvlq',
        country: 'eicu.countries.brazil',
        country_flag: BrazilFlag
      },
      {
        name: 'eicu.choirs.cek',
        country: 'eicu.countries.ukraine',
        country_flag: UkraineFlag
      }
    ]
  },
  {
    title: 'XII',
    date: 'eicu.past_editions.dates.XII',
    image: EICU_XII,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cisec',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.oac',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.caem',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.culi',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cuvar',
        country: 'eicu.countries.poland',
        country_flag: PolandFlag
      },
      {
        name: 'eicu.choirs.ctqc',
        country: 'eicu.countries.england',
        country_flag: EnglandFlag
      },
      {
        name: 'eicu.choirs.cav',
        country: 'eicu.countries.lithuania',
        country_flag: LithuaniaFlag
      }
    ]
  },
  {
    title: 'XI',
    date: 'eicu.past_editions.dates.XI',
    image: EICU_XI,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cubi',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cutl',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cupih',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.ecoromia',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cicbas',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cctutk',
        country: 'eicu.countries.slovakia',
        country_flag: SlovakiaFlag
      },
      {
        name: 'eicu.choirs.aclut',
        country: 'eicu.countries.poland',
        country_flag: PolandFlag
      },
      {
        name: 'eicu.choirs.tgc',
        country: 'eicu.countries.czech_republic',
        country_flag: CzechRepublicFlag
      }
    ]
  },
  {
    title: 'X',
    date: 'eicu.past_editions.dates.X',
    image: EICU_X,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cdpc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cgp',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cum',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.clup',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.ecoromia',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cxfgub',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.csculp',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.cuval',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.cucrua',
        country: 'eicu.countries.italy',
        country_flag: ItalyFlag
      }
    ]
  },
  {
    title: 'IX',
    date: 'eicu.past_editions.dates.IX',
    image: EICU_IX,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cluc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.sccmsf',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.oac',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cttufrj',
        country: 'eicu.countries.brazil',
        country_flag: BrazilFlag
      },
      {
        name: 'eicu.choirs.caupc',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.cucrua',
        country: 'eicu.countries.italy',
        country_flag: ItalyFlag
      }
    ]
  },
  {
    title: 'VIII',
    date: 'eicu.past_editions.dates.VIII',
    image: EICU_VIII,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cxfgub',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.cull',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.csculp',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.ucccs',
        country: 'eicu.countries.ireland',
        country_flag: IrelandFlag
      },
      {
        name: 'eicu.choirs.cfvuv',
        country: 'eicu.countries.lithuania',
        country_flag: LithuaniaFlag
      },
      {
        name: 'eicu.choirs.cfupc',
        country: 'eicu.countries.poland',
        country_flag: PolandFlag
      }
    ]
  },
  {
    title: 'VII',
    date: 'eicu.past_editions.dates.VII',
    image: EICU_VII,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cunl',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cucp',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cull',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.cl',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.ouv',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.cuszeg',
        country: 'eicu.countries.hungary',
        country_flag: HungaryFlag
      }
    ]
  },
  {
    title: 'VI',
    date: 'eicu.past_editions.dates.VI',
    image: EICU_VI,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cje',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.outmad',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cvujg',
        country: 'eicu.countries.germany',
        country_flag: GermanyFlag
      },
      {
        name: 'eicu.choirs.cull',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.coum',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.ucccs',
        country: 'eicu.countries.ireland',
        country_flag: IrelandFlag
      },

      {
        name: 'eicu.choirs.cpipup',
        country: 'eicu.countries.italy',
        country_flag: ItalyFlag
      }
    ]
  },
  {
    title: 'V',
    date: 'eicu.past_editions.dates.V',
    image: EICU_V,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cum',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.culi',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cusa',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.cuval',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.cuszol',
        country: 'eicu.countries.hungary',
        country_flag: HungaryFlag
      },
      {
        name: 'eicu.choirs.cucz',
        country: 'eicu.countries.poland',
        country_flag: PolandFlag
      }
    ]
  },
  {
    title: 'IV',
    date: 'eicu.past_editions.dates.IV',
    image: EICU_IV,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.camvp',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cfeup',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cua',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cum',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cuca',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.cuo',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.colvup',
        country: 'eicu.countries.italy',
        country_flag: ItalyFlag
      }
    ]
  },
  {
    title: 'III',
    date: 'eicu.past_editions.dates.III',
    image: EICU_III,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cdpc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.outmad',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cuca',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.ccs',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      }
    ]
  },
  {
    title: 'II',
    date: 'eicu.past_editions.dates.II',
    image: EICU_II,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.clup',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.culi',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.oac',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cagd',
        country: 'eicu.countries.bulgaria',
        country_flag: BulgariaFlag
      },
      {
        name: 'eicu.choirs.pcuo',
        country: 'eicu.countries.denmark',
        country_flag: DenmarkFlag
      }
    ]
  },
  {
    title: 'I',
    date: 'eicu.past_editions.dates.I',
    image: EICU_I,
    participants: [
      {
        name: 'eicu.choirs.cmuc',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.clup',
        country: 'eicu.countries.portugal',
        country_flag: PortugalFlag
      },
      {
        name: 'eicu.choirs.cule',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      },
      {
        name: 'eicu.choirs.cuo',
        country: 'eicu.countries.spain',
        country_flag: SpainFlag
      }
    ]
  }
];
