import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import deDeTranslations from './locales/de-DE.json';
import enGbTranslations from './locales/en-GB.json';
import esEsTranslations from './locales/es-ES.json';
import frFrTranslations from './locales/fr-FR.json';
import itItTranslations from './locales/it-IT.json';
import ptPtTranslations from './locales/pt-PT.json';

import DEFlag from '../assets/flags/germany-flag.png';
import ENFlag from '../assets/flags/united_kingdom-flag.png';
import ESFlag from '../assets/flags/spain-flag.png';
import FRFlag from '../assets/flags/france-flag.png';
import ITFlag from '../assets/flags/italy-flag.png';
import PTFlag from '../assets/flags/portugal-flag.png';

export const languages = [
  {
    language: 'pt',
    locale: 'pt-PT',
    img: PTFlag,
    translation: ptPtTranslations
  },
  {
    language: 'en',
    locale: 'en-GB',
    img: ENFlag,
    translation: enGbTranslations
  },
  {
    language: 'es',
    locale: 'es-ES',
    img: ESFlag,
    translation: esEsTranslations
  },
  {
    language: 'fr',
    locale: 'fr-FR',
    img: FRFlag,
    translation: frFrTranslations
  },
  {
    language: 'de',
    locale: 'de-DE',
    img: DEFlag,
    translation: deDeTranslations
  },
  {
    language: 'it',
    locale: 'it-IT',
    img: ITFlag,
    translation: itItTranslations
  }
];

const resources = languages.reduce(
  (acc, { language, locale, translation }) => ({
    ...acc,
    [language]: translation,
    [locale]: translation
  }),
  {}
);

const i18nConfig = {
  resources,
  fallbackLng: 'pt-PT', // default language if the browser cannot detect automatically
  defaultNS: 'translations' // default namespace
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);

export default i18n;
