import styled, { css } from 'styled-components';

export const FooterContainer = styled.div`
  background: ${(props) => props.theme.color_primary};
  bottom: 0;
  padding: 2rem 2rem 0.5rem 2rem;
  h2 {
    font-size: 40px;
    letter-spacing: 2px;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    h2 {
      font-size: 24px;
      padding-bottom: 5px;
    }
  }
`;

export const SadFooter = styled.div`
  flex-grow: 1;
`;

export const Footer = styled.footer`
  border-top: 1px solid ${(props) => props.theme.color_background};
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const VirgolinoImg = styled.img`
  height: 10vw;
  margin-left: 30px;
  @media only screen and (max-width: 1000px) {
    height: 20vh;
    margin-left: 0;
    padding-top: 15px;
  }
`;

export const RightFooter = styled.div`
  padding-left: 10rem;

  @media only screen and (max-width: 1000px) {
    padding-left: 0;
  }
`;

export const LeftFooter = styled.div`
  padding-top: 1rem;
  p,
  a {
    font-size: 14px;
  }
  @media only screen and (min-width: 1000px) {
    max-width: 50vw;
  }
`;

export const LeftFooterItem = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
  p,
  a {
    padding-left: 10px;
  }
`;

export const SocialItems = styled.div`
  padding-top: 1.5rem;
  @media only screen and (max-width: 1000px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const SocialItem = styled.a`
  padding-right: 1rem;
  height: 32px;
  @media only screen and (max-width: 1000px) {
    height: 38px;
  }
`;

export const Copyright = styled.p`
  padding-top: 10px;
  font-size: 12px;
`;

export const logoStyle = css`
  fill: white;
  height: 32px;
  width: 32px;
`;
