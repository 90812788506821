import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { NavMenuItem as StyledNavItem, NavMenuItemArrowButton } from './styled';
import NavSubMenu from './NavSubMenu';
import Translator from '../../i18n/Translator';

function NavMenuItem({ menu, vertical }) {
  const menuRef = useRef(null);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  const onMouseLeave = useCallback(() => {
    setDropdownAnchorEl(null);
  }, []);

  const onMouseEnter = useCallback(() => {
    setDropdownAnchorEl(menuRef.current);
  }, []);
  const isDropdown = Boolean(dropdownAnchorEl);

  return (
    <StyledNavItem
      onClick={menu?.submenu?.length && onMouseEnter}
      onMouseEnter={menu?.submenu?.length && onMouseEnter}
      onMouseLeave={menu?.submenu?.length && onMouseLeave}
      ref={menuRef}
      $isVertical={vertical}
    >
      <div style={{ display: 'flex' }}>
        <Link to={menu.url}>
          <Translator path={menu.title} />
        </Link>
        {!vertical && menu.submenu && (
          <NavMenuItemArrowButton
            aria-haspopup="menu"
            aria-expanded={isDropdown}
          >
            {isDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </NavMenuItemArrowButton>
        )}
      </div>

      {menu.submenu && (
        <NavSubMenu
          vertical={vertical}
          anchorEl={dropdownAnchorEl}
          items={menu.submenu}
        />
      )}
    </StyledNavItem>
  );
}
NavMenuItem.propTypes = {
  menu: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    submenu: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  vertical: PropTypes.bool
};

NavMenuItem.defaultProps = {
  vertical: false
};

export default NavMenuItem;
