import React, { useState, useEffect } from 'react';

import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

import {
  PageContainer,
  SectionContainer,
  PageTitle,
  SectionTitle,
  Text
} from '../../theme/GlobalStyles';
import {
  Goals,
  GoalItem,
  SectionImgEicu,
  ImgEicu,
  ImgTrademark,
  CardContainer,
  CardImage,
  CardImageZoom,
  CardText,
  CardChoirList,
  CardChoirItem,
  VerticalTimelineContainer,
  ImgFlag,
  TextFlag
} from './styled';
import EicuData from '../../data/EicuData';
import Translator from '../../i18n/Translator';
import EicuLogo from '../../assets/images/eicu/logo-eicu.png';
import Trademark from '../../assets/images/eicu/trademark-eicu.jpg';

function getCardText(item) {
  return (
    <CardText>
      <h2>{item.title} EICU</h2>
      <CardChoirList>
        {item.participants?.map((participant) => (
          <CardChoirItem key={`${item.title}-${participant.name}`}>
            <ImgFlag src={participant.country_flag} loading="lazy" alt="flag" />
            <TextFlag>
              <Translator path={participant.name} /> (
              <Translator path={participant.country} />)
            </TextFlag>
          </CardChoirItem>
        ))}
      </CardChoirList>
    </CardText>
  );
}

export default function EicuPage() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1159);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PageContainer>
      <PageTitle>
        <Translator path="eicu.sub_title" />
      </PageTitle>
      <SectionImgEicu>
        <ImgEicu src={EicuLogo} loading="lazy" alt="eicu-logo" />
      </SectionImgEicu>
      <SectionContainer id="xv">
        <SectionTitle>
          <Translator path="eicu.xv.title" />
        </SectionTitle>
        <Text>
          <Translator path="eicu.xv.content" />
          <a href="mailto:eicu@cmuc.pt">
            <strong> eicu@cmuc.pt</strong>
          </a>
          .
        </Text>
      </SectionContainer>
      <SectionContainer id="historia">
        <SectionTitle>
          <Translator path="eicu.history.title" />
        </SectionTitle>
        <Text>
          <Translator path="eicu.history.content" />
        </Text>
        <Goals>
          <GoalItem>
            <MusicNoteIcon />
            <Text>
              <Translator path="eicu.history.goal1" />;
            </Text>
          </GoalItem>
          <GoalItem>
            <MusicNoteIcon />
            <Text>
              <Translator path="eicu.history.goal2" />;
            </Text>
          </GoalItem>
          <GoalItem>
            <MusicNoteIcon />
            <Text>
              <Translator path="eicu.history.goal3" />;
            </Text>
          </GoalItem>
          <GoalItem>
            <MusicNoteIcon />
            <Text>
              <Translator path="eicu.history.goal4" />.
            </Text>
          </GoalItem>
        </Goals>
        <Text>
          <Translator path="eicu.history.registered_trademark" />
        </Text>
        <ImgTrademark src={Trademark} loading="lazy" alt="trademark" />
      </SectionContainer>
      <SectionContainer id="edicoes_anteriores">
        <SectionTitle>
          <Translator path="eicu.past_editions.title" />
        </SectionTitle>
        <Text>
          <Translator path="eicu.past_editions.content" />
        </Text>
      </SectionContainer>
      <SectionContainer background="true">
        <VerticalTimelineContainer>
          <VerticalTimeline className="vertical-timeline">
            {EicuData.map((item, index) => (
              <VerticalTimelineElement
                className="vertical-timeline-element"
                key={item.title}
                date={<Translator path={item.date} />}
                dateClassName="date-container"
                iconClassName="icon-container"
                textClassName="text-container"
                contentArrowStyle={{
                  borderRight: '7px solid  #333333'
                }}
              >
                <CardContainer>
                  {(index % 2 === 0 || isSmallScreen) && getCardText(item)}
                  <CardImageZoom>
                    <CardImage
                      src={item.image}
                      loading="lazy"
                      alt="card_image"
                    />
                  </CardImageZoom>
                  {!(index % 2 === 0 || isSmallScreen) && getCardText(item)}
                </CardContainer>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </VerticalTimelineContainer>
      </SectionContainer>
    </PageContainer>
  );
}
