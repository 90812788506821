import styled from 'styled-components';

export const SectionImgEicu = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20%;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    padding: 0;
  }
`;

export const ImgEicu = styled.img`
  width: 10vw;
  min-width: 100px;
  @media only screen and (max-width: 600px) {
    min-width: 150px;
  }
`;

export const Goals = styled.ul`
  padding-left: 3rem;
  padding-bottom: 1rem;
  @media only screen and (max-width: 600px) {
    padding-left: 0;
  }
`;

export const GoalItem = styled.li`
  display: flex;
`;

export const ImgTrademark = styled.img`
  display: flex;
  width: 15vw;
  min-width: 200px;
  padding-top: 1rem;
`;

export const VerticalTimelineContainer = styled.div`
  .vertical-timeline {
    max-width: none;
    width: 100%;
  }
  .vertical-timeline-element-icon {
    display: none;
  }

  .vertical-timeline-element-content-arrow {
    border-top-width: 10px !important;
    border-right-width: 15px !important;
    border-bottom-width: 10px !important;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .vertical-timeline-element-content {
    padding: 0;
    top: 30px !important;
    border-radius: 0.7em;
    @media only screen and (max-width: 600px) {
      margin-left: 0;
    }
  }

  .vertical-timeline::before {
    background-color: ${(props) => props.theme.color_primary};
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .date-container {
    color: ${(props) => props.theme.color_primary};
    font-weight: bold;
    @media only screen and (max-width: 1169px) {
      color: ${(props) => props.theme.color_secondary};
      padding: 0.5rem 1rem;
    }
  }
  .icon-container {
    background: ${(props) => props.theme.color_primary};
    box-shadow: none;
  }
  .text-container {
    background-color: ${(props) => props.theme.color_primary};
    color: ${(props) => props.theme.color_secondary};
  }
  h2 {
    color: ${(props) => props.theme.color_secondary};
  }
`;

export const CardContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const CardImageZoom = styled.div`
  display: flex;
  @media only screen and (min-width: 600px) {
    z-index: 1;
    :active {
      transform: scale(2);
    }
  }
`;

export const CardImage = styled.img`
  width: 100%;
  object-fit: contain;
  transition: transform 0.25s ease;
  padding: 0.5rem;
  @media only screen and (min-width: 600px) {
    cursor: zoom-in;
  }
  @media only screen and (max-width: 600px) {
    border-radius: 0;
    padding: 0;
  }
`;

export const CardText = styled.div`
  padding: 1rem 1rem;
  min-width: 60%;
`;

export const CardChoirList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
`;

export const CardChoirItem = styled.li`
  display: flex;
  padding-top: 1rem;
  align-items: center;
`;

export const ImgFlag = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 1rem;
`;

export const TextFlag = styled.h6``;
