import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavMenu } from './styled';
import NavMenuItems from '../../data/NavMenuData';
import NavMenuLanguages from './NavMenuLanguages';
import NavMenuItem from './NavMenuItem';

function NavigationMenu({ vertical }) {
  return (
    <Nav $isVertical={vertical}>
      <NavMenu $isVertical={vertical}>
        <>
          {NavMenuItems.map((menu) => (
            <NavMenuItem key={menu.title} menu={menu} vertical={vertical} />
          ))}
        </>
        <NavMenuLanguages vertical={vertical} />
      </NavMenu>
    </Nav>
  );
}

NavigationMenu.propTypes = {
  vertical: PropTypes.bool
};

NavigationMenu.defaultProps = {
  vertical: false
};
export default NavigationMenu;
