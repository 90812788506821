import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  NavMenuItem,
  NavMenuItemDropdown,
  NavMenuItemArrowButton,
  NavMenuItemFlagButton
} from './styled';
import Flag from './Flag';
import { languages } from '../../i18n';

function NavMenuLanguages({ vertical }) {
  const { i18n } = useTranslation();
  const [dropdown, setDropdown] = useState(false);

  function handleChangeLanguage(language) {
    i18n.changeLanguage(language);
  }

  const selectedLanguage = i18n.language;

  const selectedItem = languages.find(
    (item) =>
      item.locale === selectedLanguage || item.language === selectedLanguage
  );

  return (
    <NavMenuItem
      key={selectedItem?.locale}
      onMouseEnter={() => setDropdown(true)}
      onMouseLeave={() => setDropdown(false)}
      $isLanguage
    >
      <>
        <NavMenuItemFlagButton $isPrimary>
          <Flag image={selectedItem?.img} />
        </NavMenuItemFlagButton>

        {!vertical && (
          <NavMenuItemArrowButton aria-haspopup="menu" aria-expanded={dropdown}>
            {dropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </NavMenuItemArrowButton>
        )}

        <NavMenuItemDropdown
          $isDropdown={Boolean(dropdown) || vertical}
          $isVertical={vertical}
          $isLanguage
        >
          {languages.map((item) => (
            <NavMenuItem
              key={item.locale}
              $isDropdown={Boolean(dropdown) || vertical}
              $isVertical={vertical}
              $isLanguage
            >
              <NavMenuItemFlagButton selected={item === selectedItem}>
                <Flag
                  image={item.img}
                  onClick={() => handleChangeLanguage(item.locale)}
                />
              </NavMenuItemFlagButton>
            </NavMenuItem>
          ))}
        </NavMenuItemDropdown>
      </>
    </NavMenuItem>
  );
}

NavMenuLanguages.propTypes = {
  vertical: PropTypes.bool
};

NavMenuLanguages.defaultProps = {
  vertical: false
};

export default NavMenuLanguages;
