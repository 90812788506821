import React from 'react';

import Translator from '../../i18n/Translator';
import {
  PageContainer,
  SectionContainer,
  PageTitle
} from '../../theme/GlobalStyles';

export default function ToursPage() {
  return (
    <PageContainer>
      <PageTitle>
        <Translator path="tours.sub_title" />
      </PageTitle>
      <SectionContainer id="">{/* <NextTours /> */}</SectionContainer>
      <SectionContainer id="">{/* <PastTours /> */}</SectionContainer>
    </PageContainer>
  );
}
