import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Container,
  LeftHeader,
  MainLogo,
  RightHeader,
  ToggleButton
} from './styled';
import CMUCLogo from '../../assets/logos/cmuc-logo-shadow-big.png';
import NavMenu from './NavMenu';

function Header({ toggleDrawer }) {
  return (
    <Container>
      <LeftHeader>
        <Link to="/">
          <MainLogo src={CMUCLogo} loading="lazy" alt="logo" />
        </Link>
      </LeftHeader>

      <RightHeader>
        <NavMenu />
        <ToggleButton onClick={toggleDrawer} />
      </RightHeader>
    </Container>
  );
}

Header.propTypes = {
  toggleDrawer: PropTypes.func.isRequired
};

export default Header;
