import AdelinoMartins from '../assets/images/about/conductor_adelino_martins.jpg';
import AlvaroCarvalho from '../assets/images/about/conductor_alvaro_carvalho.jpg';
import CelestinoOrtet from '../assets/images/about/conductor_celestino_ortet.jpg';
import CesarNogueira from '../assets/images/about/conductor_cesar_nogueira.jpg';
import DiasGirao from '../assets/images/about/conductor_dias_girao.jpg';
import RaposoMarques from '../assets/images/about/conductor_raposo_marques.jpg';
import RodrigoCarvalho from '../assets/images/about/conductor_rodrigo_carvalho.jpg';

export default [
  {
    title: 'Raposo Marques',
    date: '1956 - ?',
    image: RaposoMarques
  },
  {
    title: 'Dias Girão',
    date: '1960 - ?',
    image: DiasGirao
  },
  {
    title: 'Adelino Martins',
    date: '1965 - ? , 1986 - ?',
    image: AdelinoMartins
  },
  {
    title: 'Álvaro Carvalho',
    date: '1981 - ?',
    image: AlvaroCarvalho
  },
  {
    title: 'Celestino Ortet',
    date: '?',
    image: CelestinoOrtet
  },
  {
    title: 'César Nogueira',
    date: '1996 - 2008',
    image: CesarNogueira
  },
  {
    title: 'Rodrigo Carvalho',
    date: '2008 - presente',
    image: RodrigoCarvalho
  }
];
