import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import NavMenu from './components/header/NavMenu';
import { StyledDrawer, StyledPaperForDrawer } from './styled';
import { MainLogo } from './components/header/styled';
import CMUCLogo from './assets/logos/cmuc-logo-shadow-big.png';

import GlobalStyles from './theme/GlobalStyles';
import theme from './theme/theme';
import Header from './components/header';
import Footer from './components/footer';
import RoutingData from './data/RoutingData';

function App() {
  const { pathname, hash, key } = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      setOpenDrawer(false);
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
          setOpenDrawer(false);
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header toggleDrawer={() => setOpenDrawer(!openDrawer)} />
      <Routes>
        {RoutingData.map((route) => (
          <Route key={route} exact path={route.path} element={route.page} />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
      <StyledDrawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{ component: StyledPaperForDrawer }}
      >
        <MainLogo src={CMUCLogo} loading="lazy" alt="logo" $isDrawer />
        <NavMenu vertical />
      </StyledDrawer>
    </ThemeProvider>
  );
}

export default App;
