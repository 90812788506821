export default {
  instagram: [
    'C1ftc8ctAxK',
    // 'C1VaPg-o9hj',
    // 'C1QC6f1tTRL',
    'C1NtNcENRdh',
    'C1IP_JErcoa'
    // 'C0wrmYVIY5P',
    // 'C0ZksNCI6dY',
    // 'C0WVEg-IeCy',
    // 'CzW5Fi3tzm9'
  ],
  youtube: [
    'DqEArDR4wvo',
    'ZZ_DniajJnc'
    // 'vSskHpPtmwc'
  ],
  soundcloud: ['the-lion', 'in-the-mood-bonn', 'cantigas-de-maio']
};
