import React from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import styled from 'styled-components';

import {
  FooterContainer,
  SadFooter,
  Footer,
  VirgolinoImg,
  RightFooter,
  LeftFooter,
  LeftFooterItem,
  SocialItems,
  SocialItem,
  Copyright,
  logoStyle
} from './styled';
import Translator from '../../i18n/Translator';

import { ReactComponent as FacebookLogoSVG } from '../../assets/logos/icons8-facebook.svg';
import { ReactComponent as InstagramLogoSVG } from '../../assets/logos/icons8-instagram.svg';
import { ReactComponent as BlogspotLogoSVG } from '../../assets/logos/icons8-blogger.svg';
import { ReactComponent as SpotifyLogoSVG } from '../../assets/logos/icons8-spotify.svg';
import { ReactComponent as AppleMusicLogoSVG } from '../../assets/logos/icons8-apple-music.svg';
import { ReactComponent as YoutubeLogoSVG } from '../../assets/logos/icons8-youtube.svg';
import { ReactComponent as SoundCloudLogoSVG } from '../../assets/logos/icons8-soundcloud.svg';
import VirgolinoImage from '../../assets/images/common/virgolino.png';

const FacebookLogo = styled(FacebookLogoSVG)`
  ${logoStyle}
`;
const InstagramLogo = styled(InstagramLogoSVG)`
  ${logoStyle}
`;
const BlogspotLogo = styled(BlogspotLogoSVG)`
  ${logoStyle}
`;
const SpotifyLogo = styled(SpotifyLogoSVG)`
  ${logoStyle}
`;
const AppleMusicLogo = styled(AppleMusicLogoSVG)`
  ${logoStyle}
`;
const YoutubeLogo = styled(YoutubeLogoSVG)`
  ${logoStyle}
`;

const SoundCloudLogo = styled(SoundCloudLogoSVG)`
  ${logoStyle}
`;

export default function FooterC() {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <SadFooter>
        <h2>
          <Translator path="footer.choir_dare" />
        </h2>
        <p>
          <Translator path="footer.rehersals" />
        </p>

        <br />
        <Footer>
          <LeftFooter>
            <LeftFooterItem>
              <EmailOutlinedIcon />
              <a href="mailto:coro.misto.uc@gmail.com">
                coro.misto.uc@gmail.com
              </a>
            </LeftFooterItem>
            <LeftFooterItem>
              <LocationOnOutlinedIcon />
              <a
                href="https://maps.app.goo.gl/ozJFhqBQqkQVynVa7"
                target="_blank"
                rel="noreferrer noopener"
              >
                Rua Padre António Vieira, Instalações Académicas 3000-315
                Coimbra
              </a>
            </LeftFooterItem>
            <LeftFooterItem>
              <LocalShippingOutlinedIcon />
              <p>Apartado 1105, 3000-104 Coimbra</p>
            </LeftFooterItem>
          </LeftFooter>
          <RightFooter>
            <SocialItems>
              <SocialItem
                href="https://www.facebook.com/coromistodauniversidadedecoimbra"
                aria-label="Facebook"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FacebookLogo />
              </SocialItem>
              <SocialItem
                href="https://www.instagram.com/coro_misto_uc/"
                aria-label="Instagram"
                target="_blank"
                rel="noreferrer noopener"
              >
                <InstagramLogo />
              </SocialItem>
              <SocialItem
                href="https://atecristocantounocoromisto.blogspot.com/"
                aria-label="Blog"
                target="_blank"
                rel="noreferrer noopener"
              >
                <BlogspotLogo />
              </SocialItem>
              <SocialItem
                href="https://open.spotify.com/intl-pt/artist/1ap2PcsrUxKfGdnKRzAhkK?si=45gDHDK4QX-znZx_FR_K0w"
                aria-label="Spotify"
                target="_blank"
                rel="noreferrer noopener"
              >
                <SpotifyLogo />
              </SocialItem>
              <SocialItem
                href="https://music.apple.com/pt/artist/coro-misto-da-universidade-de-coimbra/1444317294"
                aria-label="Apple Music"
                target="_blank"
                rel="noreferrer noopener"
              >
                <AppleMusicLogo />
              </SocialItem>
              <SocialItem
                href="https://www.youtube.com/@coro_misto_uc"
                aria-label="Youtube"
                target="_blank"
                rel="noreferrer noopener"
              >
                <YoutubeLogo />
              </SocialItem>
              <SocialItem
                href="https://soundcloud.com/coro-misto-da-uc"
                aria-label="Sound Cloud"
                target="_blank"
                rel="noreferrer noopener"
              >
                <SoundCloudLogo />
              </SocialItem>
            </SocialItems>
            <Copyright>
              © {currentYear} CMUC - <Translator path="footer.copyright" />
            </Copyright>
          </RightFooter>
        </Footer>
      </SadFooter>
      <VirgolinoImg src={VirgolinoImage} loading="lazy" alt="virgolino" />
    </FooterContainer>
  );
}
