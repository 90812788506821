export default [
  {
    id: 1,
    title: 'home.title',
    url: '/'
  },
  {
    id: 2,
    title: 'about.title',
    url: '/sobre',
    submenu: [
      {
        id: 1,
        title: 'about.history.title',
        url: '/sobre/#historia'
      },
      {
        id: 2,
        title: 'about.activities.title',
        url: '/sobre/#atividades'
      },
      {
        id: 3,
        title: 'about.conductor.title',
        url: '/sobre/#direcao_artistica'
      },
      {
        id: 4,
        title: 'about.discography.title',
        url: '/sobre/#discografia'
      }
    ]
  },
  /*   {
    id: 3,
    title: 'events.title',
    url: '/eventos'
  },
  {
    id: 4,
    title: 'tours.title',
    url: '/digressoes'
  }, */
  {
    id: 5,
    title: 'eicu.title',
    url: '/eicu',
    submenu: [
      {
        id: 1,
        title: 'eicu.xv.title',
        url: '/eicu/#xv'
      },
      {
        id: 2,
        title: 'eicu.contest.title',
        url: '/eicu/concurso'
      },
      {
        id: 3,
        title: 'eicu.history.title',
        url: '/eicu/#historia'
      },
      {
        id: 4,
        title: 'eicu.past_editions.title',
        url: '/eicu/#edicoes_anteriores'
      }
    ]
  }
];
