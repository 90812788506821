import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Translator from '../../i18n/Translator';
import {
  PageContainer,
  SectionContainer,
  PageTitle,
  SectionTitle,
  SubTitle,
  Text
} from '../../theme/GlobalStyles';
import {
  VerticalTimelineContainer,
  CardImage,
  DiscographyRow,
  CDContainer,
  ImgZoom,
  ImgFoundation,
  ImgCD,
  TextCD
} from './styled';
import CDMiserere from '../../assets/images/about/cd_miserere.jpg';
import CDCantares from '../../assets/images/about/cd_cantares.jpg';
import CDBonnPavia from '../../assets/images/about/cd_debonnapavia.jpg';
import CDLuzDeNatal from '../../assets/images/about/cd_luzdenatal.jpg';
import CMUCFoundation from '../../assets/images/about/cmuc-fundacao.jpg';
import CasseteCMUC from '../../assets/images/about/cassete_coro.jpg';
import CDCantarCoimbra from '../../assets/images/about/cd_cantar_coimbra.jpg';
import CDCarlosSeixas from '../../assets/images/about/cd_carlos_seixas.jpg';
import CDCorosDeCoimbra from '../../assets/images/about/cd_coros_de_coimbra.jpg';
import CDNatalDosSimples from '../../assets/images/about/cd_natal_dos_simples.jpg';
import CDIndiferenca from '../../assets/images/about/cd_indiferenca.jpg';

import directorItems from '../../data/DirectorData';

export default function AboutPage() {
  return (
    <PageContainer>
      <PageTitle>
        <Translator path="about.sub_title" />
      </PageTitle>
      <SectionContainer id="historia">
        <SectionTitle>
          <Translator path="about.history.title" />
        </SectionTitle>
        <Text>
          <Translator path="about.history.content" />
        </Text>
        <ImgZoom>
          <ImgFoundation src={CMUCFoundation} loading="lazy" alt="foundation" />
        </ImgZoom>
      </SectionContainer>
      <SectionContainer id="atividades">
        <SectionTitle>
          <Translator path="about.activities.title" />
        </SectionTitle>
        <Text>
          <Translator path="about.activities.content" />
        </Text>
      </SectionContainer>
      <SectionContainer id="direcao_artistica">
        <SectionTitle>
          <Translator path="about.conductor.title" />
        </SectionTitle>
        <Text>
          <Translator path="about.conductor.content" />
        </Text>
        <VerticalTimelineContainer>
          <VerticalTimeline
            className="vertical-timeline"
            layout="1-column-left"
          >
            {directorItems.map((item) => (
              <VerticalTimelineElement
                className="vertical-timeline-element"
                key={item.title}
                date={item.date}
                dateClassName="date-container"
                iconClassName="icon-container"
                textClassName="text-container"
                contentArrowStyle={{
                  borderRight: '7px solid  #333333'
                }}
              >
                <ImgZoom>
                  <CardImage src={item.image} loading="lazy" alt="card_image" />
                </ImgZoom>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </VerticalTimelineContainer>
      </SectionContainer>
      <SectionContainer id="discografia">
        <SectionTitle>
          <Translator path="about.discography.title" />
        </SectionTitle>
        <DiscographyRow>
          <CDContainer>
            <a
              type="button"
              href="https://open.spotify.com/album/3VNljMic9tsXYnEF3G2mso"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Spotify Link"
            >
              <ImgCD src={CDBonnPavia} loading="lazy" alt="cd_bonnpavia" />
            </a>
            <TextCD>2015</TextCD>
          </CDContainer>
          <Text>
            <Translator path="about.discography.debonnapavia" />
          </Text>
        </DiscographyRow>
        <DiscographyRow>
          <CDContainer>
            <ImgCD src={CDCantares} loading="lazy" alt="cd_cantares" />
            <TextCD>2008</TextCD>
          </CDContainer>
          <Text>
            <Translator path="about.discography.cantares" />
          </Text>
        </DiscographyRow>
        <DiscographyRow>
          <CDContainer>
            <ImgCD src={CDMiserere} loading="lazy" alt="cd_miserere" />
            <TextCD>2005</TextCD>
          </CDContainer>
          <Text>
            <Translator path="about.discography.miserere" />
          </Text>
        </DiscographyRow>
        <DiscographyRow>
          <CDContainer>
            <ImgCD src={CasseteCMUC} loading="lazy" alt="cassete_cmuc" />
            <TextCD>1989</TextCD>
          </CDContainer>
          <Text>
            <Translator path="about.discography.cassete" />
          </Text>
        </DiscographyRow>
        <SubTitle>
          <Translator path="about.discography.others.title" />
        </SubTitle>
        <Text>
          <Translator path="about.discography.others.description" />
        </Text>
        <br />
        <DiscographyRow>
          <CDContainer>
            <a
              type="button"
              href="https://open.spotify.com/intl-pt/album/5i6zOrFRim0BdlNvyjpsHJ"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Spotify Link"
            >
              <ImgCD src={CDLuzDeNatal} loading="lazy" alt="cd_luzdenatal" />
            </a>
            <TextCD>2018</TextCD>
          </CDContainer>
          <Text>
            <Translator path="about.discography.others.luzdenatal" />
          </Text>
        </DiscographyRow>
        <DiscographyRow>
          <CDContainer>
            <ImgCD src={CDNatalDosSimples} alt="cd_natal_dos_simples" />
            <TextCD>2005</TextCD>
          </CDContainer>
          <Text>
            <Translator path="about.discography.others.natal_dos_simples" />
          </Text>
        </DiscographyRow>
        <DiscographyRow>
          <CDContainer>
            <ImgCD src={CDCarlosSeixas} loading="lazy" alt="cd_carlos_seixas" />
            <TextCD>2004</TextCD>
          </CDContainer>
          <Text>
            <Translator path="about.discography.others.carlos_seixas" />
          </Text>
        </DiscographyRow>
        <DiscographyRow>
          <CDContainer>
            <ImgCD
              src={CDCantarCoimbra}
              loading="lazy"
              alt="cd_cantar_coimbra"
            />
            <TextCD>2004</TextCD>
          </CDContainer>
          <Text>
            <Translator path="about.discography.others.cantar_coimbra" />
          </Text>
        </DiscographyRow>
        <DiscographyRow>
          <CDContainer>
            <ImgCD
              src={CDCorosDeCoimbra}
              loading="lazy"
              alt="cd_coros_de_coimbra"
            />
            <TextCD>1997</TextCD>
          </CDContainer>
          <Text>
            <Translator path="about.discography.others.coros_de_coimbra" />
          </Text>
        </DiscographyRow>
        <DiscographyRow>
          <CDContainer>
            <ImgCD src={CDIndiferenca} loading="lazy" alt="cd_indiferenca" />
            <TextCD>1996</TextCD>
          </CDContainer>
          <Text>
            <Translator path="about.discography.others.indiferenca" />
          </Text>
        </DiscographyRow>
      </SectionContainer>
    </PageContainer>
  );
}
