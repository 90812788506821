import styled from 'styled-components';
import RoomBackground from '../../assets/images/common/room_no_background_10.png';

export const HomeContainer = styled.div``;

export const TitleContainer = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    background-image: url(${RoomBackground});
    background-size: 350%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0rem 2rem;
    color: ${(props) => props.theme.color_primary};
  }
`;

export const TitleText = styled.h1`
  @media only screen and (max-width: 600px) {
    text-transform: uppercase;
    text-align: center;
    font-size: 2em;
  }
`;

export const SwiperContainer = styled.div`
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: ${(props) => props.theme.color_primary};
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .swiper-button-prev {
    color: ${(props) => props.theme.color_primary};
    opacity: 80%;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .swiper-button-next {
    color: ${(props) => props.theme.color_primary};
    opacity: 80%;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
`;

export const ImgSwiper = styled.img`
  opacity: 0.8;
  height: calc(100vh - 4.5rem);
  width: 100%;
  object-fit: cover;
  @media only screen and (max-width: 600px) {
    height: calc(40vh - 4.5rem);
  }
`;

export const InstagramPostsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  div {
    min-width: 328px;
    width: 25vw !important;
  }
`;

export const YouTubeVideosContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  > div {
    padding: 2rem;
  }
  @media only screen and (max-width: 600px) {
    > div {
      padding: 0 0 10px 0;
    }
  }
`;

export const SoundCloudContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const SoundCloudRootObject = (component) => styled(component)`
  width: 60% !important;
  max-width: 800px;
  height: 150px !important;
  padding: 10px;
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
`;
