import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';

import { Menu } from '@mui/material';

export const Container = styled.header`
  padding: 5px;
  height: 65px;
  width: 100vw;
  position: fixed;
`;

export const LeftHeader = styled.div`
  padding-left: 1rem;
  @media only screen and (max-width: 600px) {
    position: absolute;
    right: calc(64px + 1rem);
  }
`;

export const MainLogo = styled.img`
  position: absolute;
  top: 1rem;
  width: 164px;
  height: 164px;
  animation: adjust-logo linear both;
  animation-timeline: scroll();
  animation-range: 0 300px;

  @keyframes adjust-logo {
    to {
      top: 0.4rem;
      width: 64px;
      height: 64px;
    }
  }

  @media only screen and (max-width: 600px) {
    top: 0 !important;
    width: 64px !important;
    height: 64px !important;
    position: ${(props) => props.$isDrawer && 'relative'};
    margin-left: ${(props) => props.$isDrawer && '0.5rem'};
    margin-top: ${(props) => props.$isDrawer && '0.5rem'};
  }
`;

export const RightHeader = styled.div`
  display: flex;
  padding-right: 2rem;
  @media only screen and (max-width: 600px) {
    position: absolute;
    left: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 0.5rem;
  }
`;

export const Nav = styled.nav`
  display: flex;
  text-align: left;
  @media only screen and (max-width: 600px) {
    display: ${(props) => (props.$isVertical ? 'flex' : 'none')};
  }
`;

export const ToggleButton = styled(MenuIcon)`
  width: 3rem !important;
  height: 3rem !important;
  padding-top: 8px;
  display: none !important;
  @media only screen and (max-width: 600px) {
    display: flex !important;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  flex-direction: ${(props) => (props.$isVertical ? 'column' : 'row')};
  width: 100%;
`;

export const NavMenuItem = styled.div`
  display: flex;
  position: relative;
  align-items: ${(props) => (props.$isVertical ? 'flex-start' : 'center')};
  margin: ${(props) => (props.$isLanguage ? '0.5rem 0.6rem' : '1rem')};
  flex-direction: ${(props) => props.$isVertical && 'column'};
  img {
    width: 30px;
    height: 30px;
  }
  @media only screen and (max-width: 600px) {
    img {
      width: 35px;
      height: 35px;
      padding: 5px 10px;
    }
  }
`;

export const NavMenuItemArrowButton = styled.button`
  display: flex;
  background-color: transparent;
  svg {
    height: 0.9em;
  }
`;

export const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    font-size: 1rem;
    background-color: ${(props) => props.theme.color_primary};
    opacity: 0.9;
    border-radius: 0.8rem;
    color: ${(props) => props.theme.color_secondary} !important;
  }
`;

export const NavMenuItemFlagButton = styled.button`
  background: none;
  opacity: ${(props) => (props.selected ? '0.5' : '')};
  pointer-events: ${(props) => (props.selected ? 'none' : '')};
  @media only screen and (max-width: 600px) {
    margin-bottom: ${(props) => props.$isPrimary && '1rem'};
    opacity: ${(props) => (props.selected ? '1' : '0.4')};
    display: ${(props) => props.$isPrimary && 'none'} !important;
  }
`;

export const NavMenuItemDropdown = styled.ul`
  position: ${(props) => (props.$isVertical ? 'inherit' : 'absolute')};
  z-index: 999;
  top: ${(props) => {
    if (props.$isVertical) {
      return '0';
    }
    if (props.$isLanguage) {
      return '3rem';
    }
    return '2rem';
  }};
  right: ${(props) => {
    if (props.$isVertical) {
      return '0';
    }
    if (props.$isLanguage) {
      return '0.2rem';
    }
    return undefined;
  }};
  font-size: 0.9rem;
  background-color: ${(props) => props.theme.color_primary};
  opacity: ${(props) => (props.$isVertical ? '1' : '0.9')};
  border-radius: ${(props) => (props.$isVertical ? '0' : '0.8rem')};
  overflow: hidden;
  max-height: ${(props) => (props.$isDropdown ? '50rem;' : '0')};
  transition: 0.4s ease-in-out;
  width: ${(props) => props.$isVertical && '100%'};
  padding-top: ${(props) => !props.$isVertical && props.$isDropdown && '1rem'};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: ${(props) => (props.$isVertical ? 'row' : 'column')};
  ${NavMenuItem} {
    margin: ${(props) => props.$isLanguage && props.$isVertical && '0'};
  }
`;
