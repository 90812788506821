import styled from 'styled-components';

export const SectionImgEicu = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20%;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    padding: 0;
  }
`;

export const ImgEicu = styled.img`
  width: 10vw;
  min-width: 100px;
  @media only screen and (max-width: 600px) {
    min-width: 150px;
  }
`;

export const ImgZoom = styled.div`
  @media only screen and (min-width: 600px) {
    z-index: 1;
    :active {
      transform: scale(1.5);
    }
  }
`;

export const ImgCartaz = styled.img`
  @media only screen and (min-width: 600px) {
    cursor: zoom-in;
  }
  margin: auto;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  max-height: 80vh;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Article = styled.h3`
  text-align: center;
  margin: 40px 0 5px 0;
`;

export const ArticleTitle = styled.h4`
  text-align: center;
  margin-bottom: 10px;
`;

export const ArticleText = styled.ol`
  li {
    list-style: auto;
    margin-bottom: 5px;
    a {
      text-decoration: underline;
    }
    ul,
    ol {
      li {
        text-align: left;
        margin: 5px 0;
        list-style: inside;
      }
    }
  }
`;

export const RegulationText = styled.div`
  padding-bottom: 1rem;
`;
