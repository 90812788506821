import React from 'react';

import Translator from '../../i18n/Translator';
import {
  PageContainer,
  SectionContainer,
  PageTitle
} from '../../theme/GlobalStyles';

export default function EventsPage() {
  return (
    <PageContainer>
      <PageTitle>
        <Translator path="events.title" />
      </PageTitle>
      <SectionContainer id="">{/* <NextEvents /> */}</SectionContainer>
      <SectionContainer id="">{/* <HighlightsEvents /> */}</SectionContainer>
      <SectionContainer id="">{/* <RecurrentEvents /> */}</SectionContainer>
    </PageContainer>
  );
}
