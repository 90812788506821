export const ThemeMain = {
  color_primary: '#333333',
  color_secondary: '#FFFFFF',
  color_background: '#EEEEEE',
  font_body: 'Open Sans',
  font_size: '16px',
  font_size_mobile: '14px',
  font_title: 'Open Sans'
};

export const ThemeLgbt = {
  color_primary: '#933EBD',
  color_secondary: '#57BCDE',
  color_background: '#F2A4FC',
  font_body: "'Trirong', sans-serif",
  font_size: '22px',
  font_size_mobile: '14px',
  font_title: "'Sofia', sans-serif"
};

export default ThemeMain;
