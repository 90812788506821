import React, { Suspense } from 'react';
import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { InstagramEmbed, YouTubeEmbed } from 'react-social-media-embed';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import {
  PageContainer,
  SectionContainer,
  Text
} from '../../theme/GlobalStyles';
import {
  HomeContainer,
  TitleContainer,
  TitleText,
  SwiperContainer,
  ImgSwiper,
  InstagramPostsContainer,
  YouTubeVideosContainer,
  SoundCloudContainer,
  SoundCloudRootObject
} from './styled';
import SocialMediaPostsData from '../../data/SocialMediaPostsData';
import Translator from '../../i18n/Translator';

const SoundCloudStyled = SoundCloudRootObject(ReactPlayer);
const images = require.context('../../assets/images/home/carousel', true);
const imageList = images.keys().map((image) => images(image));

export default function HomePage() {
  return (
    <HomeContainer>
      <TitleContainer>
        <TitleText>
          <Translator path="about.sub_title" />
        </TitleText>
      </TitleContainer>
      <SwiperContainer>
        <Swiper
          navigation
          pagination={{
            clickable: true,
            enabled: true
          }}
          modules={[Navigation, Pagination, Autoplay]}
          centeredSlides
          centeredSlidesBounds
          loop
          autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
        >
          {imageList.map((image) => (
            <SwiperSlide key={image}>
              <ImgSwiper src={image} loading="lazy" alt="carousel" />
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
      <PageContainer>
        <SectionContainer id="biografia">
          <Text>
            <Translator path="home.biography.content" />
          </Text>
        </SectionContainer>
        <Suspense>
          <SectionContainer id="redes_sociais" background="true">
            <InstagramPostsContainer>
              {SocialMediaPostsData.instagram.map((url) => (
                <InstagramEmbed
                  key={url}
                  url={`https://www.instagram.com/p/${url}`}
                  width="100%"
                />
              ))}
            </InstagramPostsContainer>
            <YouTubeVideosContainer>
              {SocialMediaPostsData.youtube.map((url) => (
                <YouTubeEmbed
                  key={url}
                  url={`https://www.youtube.com/watch?v=${url}`}
                  width={325}
                  height={220}
                />
              ))}
            </YouTubeVideosContainer>
            <SoundCloudContainer>
              {SocialMediaPostsData.soundcloud.map((url) => (
                <SoundCloudStyled
                  key={url}
                  url={`https://soundcloud.com/coro-misto-da-uc/${url}`}
                  config={{
                    soundcloud: {
                      options: {
                        sharing: true,
                        visual: false
                      }
                    }
                  }}
                />
              ))}
            </SoundCloudContainer>
          </SectionContainer>
        </Suspense>
      </PageContainer>
    </HomeContainer>
  );
}
