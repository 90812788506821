import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { NavMenuItem, NavMenuItemDropdown } from './styled';
import Translator from '../../i18n/Translator';

function NavSubMenu({ items, anchorEl, onMouseLeave, vertical }) {
  const isDropdown = Boolean(anchorEl) || Boolean(vertical);
  return (
    <NavMenuItemDropdown $isDropdown={isDropdown} $isVertical={vertical}>
      <div onMouseLeave={onMouseLeave}>
        {items.map((submenu) => (
          <NavMenuItem
            key={submenu.id}
            onClick={onMouseLeave}
            $isVertical={vertical}
          >
            <Link to={submenu.url}>
              <Translator path={submenu.title} />
            </Link>
          </NavMenuItem>
        ))}
      </div>
    </NavMenuItemDropdown>
  );
}

NavSubMenu.propTypes = {
  items: PropTypes.instanceOf(Object).isRequired,
  anchorEl: PropTypes.instanceOf(Object),
  onMouseLeave: PropTypes.func,
  vertical: PropTypes.bool
};
NavSubMenu.defaultProps = {
  anchorEl: null,
  vertical: false,
  onMouseLeave: null
};

export default NavSubMenu;
