import styled from 'styled-components';

import { Drawer } from '@mui/material';

export const StyledDrawer = styled(Drawer)`
  width: 13rem;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.color_primary};
  color: ${(props) => props.theme.color_secondary};
`;

export const StyledPaperForDrawer = styled.div`
  width: 13rem;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.color_primary} !important;
  color: ${(props) => props.theme.color_secondary} !important;
`;
