import styled from 'styled-components';

export const ImgZoom = styled.div`
  @media only screen and (min-width: 600px) {
    z-index: 1;
    :active {
      transform: scale(1.5);
    }
  }
`;

export const ImgFoundation = styled.img`
  @media only screen and (min-width: 600px) {
    cursor: zoom-in;
  }
  margin: auto;
  display: flex;
  width: 100%;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    padding: 1rem 0;
    width: 100%;
  }
`;

export const VerticalTimelineContainer = styled.div`
  display: none;
  .vertical-timeline {
    max-width: none;
  }
  .vertical-timeline-element-content {
    padding: 0;
    width: 100px;
  }
  .vertical-timeline::before {
    background-color: ${(props) => props.theme.color_primary};
  }
  .date-container {
    color: ${(props) => props.theme.color_primary};
    font-weight: bold;
    position: absolute;
    left: -9em;
  }
  .icon-container {
    background: ${(props) => props.theme.color_primary};
    box-shadow: none;
  }
  .text-container {
    background-color: ${(props) => props.theme.color_primary};
  }
`;

export const CardImage = styled.img`
  @media only screen and (min-width: 600px) {
    cursor: zoom-in;
  }
  object-fit: cover;
  border-radius: 0.3rem;
  width: 100px;
`;

export const DiscographyRow = styled.div`
  padding-bottom: 2rem;
  display: flex;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const CDContainer = styled.div`
  @media only screen and (max-width: 1000px) {
    padding-bottom: 1rem;
    align-self: center;
  }
`;

export const ImgCD = styled.img`
  width: 15vw;
  min-width: 200px;
  padding-right: 1rem;
  @media only screen and (max-width: 600px) {
    width: 40vw;
  }
`;

export const TextCD = styled.h4``;
