import React from 'react';

import HomePage from '../pages/home';
import AboutPage from '../pages/about';
import EventsPage from '../pages/events';
import ToursPage from '../pages/tours';
import EicuPage from '../pages/eicu';
import ContestPage from '../pages/eicu/contest';

export default [
  {
    path: '/',
    page: <HomePage />,
    isExact: true
  },
  {
    path: '/sobre',
    page: <AboutPage />
  },
  {
    path: '/eventos',
    page: <EventsPage />
  },
  {
    path: '/digressoes',
    page: <ToursPage />
  },
  {
    path: '/eicu',
    page: <EicuPage />
  },
  {
    path: '/eicu/concurso',
    page: <ContestPage />
  }
];
